import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
// LOCAL CUSTOM COMPONENTS

import SearchResult from "./components/search-result.jsx";
import CategoryDropdown from "./components/category-dropdown.jsx";
// LOCAL CUSTOM HOOKS

import useSearch from "./hooks/use-search.jsx";
// CUSTOM ICON COMPONENT

import Search from "@/icons/Search";
import {useEffect, useState} from "react";
import {router} from "@inertiajs/react";

export default function SearchInputWithCategory({categories, query, category}) {
    const [value, setValue] = useState(query || '');
    const [active, setActive] = useState(false);
    const [navigating, setNavigating] = useState(false);
    const {
        categoryTitle,
        parentRef,
        resultList,
        handleCategoryChange,
        handleSearch,
    } = useSearch();
    const INPUT_PROPS = {
        sx: {
            border: 0,
            height: 44,
            padding: 0,
            overflow: "hidden",
            backgroundColor: "grey.200",
            "& .MuiOutlinedInput-notchedOutline": {
                border: 0
            }
        },
        startAdornment: <Box mr={2} px={2} display="grid" alignItems="center" justifyContent="center"
                             borderRight="1px solid" borderColor="grey.400">
            <Search sx={{
                fontSize: 17,
                color: "grey.600"
            }}/>
        </Box>,
        endAdornment: <CategoryDropdown title={categoryTitle} handleChange={handleCategoryChange}
                                        categories={categories} category={category}/>
    };
    useEffect(() => {
        handleCategoryChange({
            title: category ? category?.name : "All Categories",
            value: category ? category?.id : "*"
        })
    }, [category]);
    useEffect(() => {
        if(query) {
            handleSearch(value, setValue);
        }
    }, []);
    return <Box position="relative" flex="1 1 0" maxWidth="670px" mx="auto" {...{
        ref: parentRef
    }}>
        <TextField value={value} fullWidth variant="outlined" placeholder="Searching for..."
                   onChange={e => handleSearch(e.target?.value, setValue)} InputProps={INPUT_PROPS}
                    onKeyDown={e => {
                        if (e.key === "Enter" && value?.length > 0) {
                            router.visit(`/products/search?q=${encodeURIComponent(e.target?.value)}${categoryTitle!=='All Categories'?`&category=${encodeURIComponent(categoryTitle)}`:''}`)
                        }
                        if (e.key === "Enter" && value === undefined) {
                            router.visit('/products')
                        }
                    }}
                   onFocus={() => {
                       setActive(true);
                   }}
                   onBlur={() => {
                       if(active) {
                           const timeout = setTimeout(() => setActive(false), 250);
                       }
                   }}
        />

        {
            /* SHOW SEARCH RESULT LIST */
        }
        { value?.length > 0 && !navigating && active &&
            <SearchResult results={resultList} value={value} setNavigating={setNavigating}/>
        }
    </Box>;
}
