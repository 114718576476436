import { Fragment } from "react";
import { Heading, StyledLink } from "../styles/index.js";
import { CUSTOMER_CARE_LINKS } from "../data/index.js";
// ==============================================================


// ==============================================================
export default function CustomerCareLinks({
  isDark
}) {
  return <Fragment>
      <Heading style={{ color: "#FFF"}}>Customer Care</Heading>

      {CUSTOMER_CARE_LINKS.map((item, ind) => <StyledLink isDark={isDark} href={item.href} key={ind}>
          {item.title}
        </StyledLink>)}
    </Fragment>;
}
