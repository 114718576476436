import Box from "@mui/material/Box";
import { FlexBox } from "@/components/flex-box";
import { H6 } from "@/components/Typography";
import Image from "@/components/BazaarImage";
import { Checkbox } from "@mui/material";
import useCart from "@/hooks/useCart";
import { useEffect, useState } from "react";

export default function MiniCartHeader({ shopDetails }) {
    const { state, dispatch } = useCart();

    const itemsInsideShop = state.cart.filter(
        (item) => item.shop.id === shopDetails.id,
    );
    const itemConfirmCheckoutStatus = itemsInsideShop?.map(
        (item) => item.confirmCheckout === true,
    );
    const allItemsChecked = itemConfirmCheckoutStatus?.every(
        (item) => item === true,
    );
    const [checked, setChecked] = useState(allItemsChecked);
    useEffect(() => {
        setChecked(allItemsChecked);
    }, [allItemsChecked]);
    const handleBulkCheckbox = (e) => {
        setChecked(e.target.checked);
        dispatch({
            type: "SET_CONFIRM_CHECKOUT_BULK",
            payload: {
                itemsInsideShop,
                bulkConfirmed: e.target.checked,
            },
        });
    };

    return (
        <FlexBox
            py={2}
            px={2.5}
            key={shopDetails?.name}
            alignItems="center"
            borderBottom="1px solid"
            borderColor="divider"
        >
            <Box sx={{ alignSelf: "center" }}>
                <Checkbox
                    checked={checked}
                    sx={{ mx: 1 }}
                    onChange={handleBulkCheckbox}
                />
            </Box>
            <Image
                alt={shopDetails?.name}
                width={80}
                height={80}
                display="block"
                src={`/storage/${shopDetails?.logo}`}
            />
            <Box pl={2}>
                <H6>{shopDetails?.name}</H6>
            </Box>
        </FlexBox>
    );
}
