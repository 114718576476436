import MenuItem from "@mui/material/MenuItem";
import TouchRipple from "@mui/material/ButtonBase";
import useTheme from "@mui/material/styles/useTheme";
// MUI ICON COMPONENT

import KeyboardArrowDownOutlined from "@mui/icons-material/KeyboardArrowDownOutlined";
// GLOBAL CUSTOM COMPONENT

import BazaarMenu from "@/components/BazaarMenu";
// STYLED COMPONENT

import {DropDownHandler} from "../styles/index.js";
import {useState} from "react";


export default function CategoryDropdown({
                                             title,
                                             handleChange,
                                             categories,
                                             category,
                                         }) {
    const {
        palette,
        breakpoints
    } = useTheme();
    const catArray = categories.map(item => ({
        title: item.name,
        value: item.id,
    }));
    catArray.unshift({
        title: "All Categories",
        value: "*"
    });
    return <BazaarMenu direction="left" sx={{
        zIndex: breakpoints.down("md") ? 99999 : 1502
    }} handler={e => <DropDownHandler style={{backgroundColor: palette.primary[500], color: palette.grey[100]}} component={TouchRipple} onClick={e}>
        {title}
        <KeyboardArrowDownOutlined fontSize="small" color="inherit"/>
    </DropDownHandler>} options={onClose => {
        return catArray.map(item => <MenuItem key={item.value} onClick={() => {
            handleChange(item);
            onClose();
        }}>
            {item.title}
        </MenuItem>);
    }}/>;
}
