import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
// MUI ICON COMPONENT
import LoginIcon from '@mui/icons-material/Login';
import PersonOutline from "@mui/icons-material/PersonOutline";
// CUSTOM ICON COMPONENT

import ShoppingBagOutlined from "@/icons/ShoppingBagOutlined";
// GLOBAL CUSTOM HOOK


import useCart from "@/hooks/useCart";
import {H5, H6} from "@/components/Typography.jsx";
import {useEffect, useState} from "react";
import {ListItemIcon, Popover} from "@mui/material";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import Divider from "@mui/material/Divider";
import {Logout, PersonAdd} from "@mui/icons-material";
import Settings from "@mui/icons-material/Settings";
import Menu from "@mui/material/Menu";
import {Link, router} from "@inertiajs/react";
import NotificationsPopover from "@/components/layouts/vendor-dashboard/dashboard-navbar/notification-popover.jsx";
import {FlexBox} from "@/components/flex-box/index.js";
// ==============================================================


// ==============================================================
export default function LoginCartButtons({
                                             toggleDialog,
                                             toggleSidenav,
                                             auth,
                                         }) {
    const {
        state
    } = useCart();
    const ICON_COLOR = {
        color: "grey.600",
    };
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return <div>

        <FlexBox gap={1} alignItems="center">
            {
                auth?.user &&
                <NotificationsPopover />
            }

            {
                auth?.user ?
                    <IconButton onClick={handleClick}>
                        <PersonOutline sx={ICON_COLOR}/>
                    </IconButton>
                    :
                    <IconButton onClick={toggleDialog}>
                        <LoginIcon sx={ICON_COLOR}/>
                    </IconButton>

            }

            <Badge badgeContent={state.cart.length} color="primary">
                <IconButton onClick={toggleSidenav}>
                    <ShoppingBagOutlined sx={ICON_COLOR}/>
                </IconButton>
            </Badge>
        </FlexBox>
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&::before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={{horizontal: 'right', vertical: 'top'}}
            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
        >
            <MenuItem onClick={handleClose}>
                <Link href='/user/profile' style={{display: 'flex'}}>
                    <ListItemIcon>
                        <AccountCircleIcon fontSize="small"/>
                    </ListItemIcon>
                    <H6>My Profile</H6>
                </Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
                <Link href='/user/orders' style={{display: 'flex'}}>
                    <ListItemIcon>
                        <ShoppingBagOutlinedIcon fontSize="small"/>
                    </ListItemIcon>
                    <H6>My Orders</H6>
                </Link>
            </MenuItem>
            <Divider/>
            <MenuItem onClick={() => {
                setAnchorEl(null);
                localStorage.removeItem("cart");
                router.post(route('logout'));
                window.location.replace('/');
            }}>
                <ListItemIcon>
                    <Logout fontSize="small"/>
                </ListItemIcon>
                Logout
            </MenuItem>
        </Menu>
    </div>;
}
