import { router } from "@inertiajs/react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
// GLOBAL CUSTOM HOOK

import useCart from "@/hooks/useCart";
// LOCAL CUSTOM COMPONENTS

import TopHeader from "./components/top-header.jsx";
import MiniCartItem from "./components/cart-item.jsx";
import EmptyCartView from "./components/empty-view.jsx";
import BottomActions from "./components/bottom-actions.jsx";
// GLOBAL CUSTOM COMPONENT

import Scrollbar from "@/components/scrollbar";
// CUSTOM UTILS LIBRARY FUNCTION

import { currency } from "@/lib";
import MiniCartHeader from "./components/cart-header.jsx";
// CUSTOM DATA MODEL

// =========================================================
export default function MiniCart({ toggleSidenav }) {
    const { state, dispatch } = useCart();
    const cartList = state.cart;

    const shopWithProducts = Object.values(
        state.cart.reduce((acc, product) => {
            const shopId = product?.shop?.id;

            // Initialize the group if it doesn't exist
            if (!acc[shopId]) {
                acc[shopId] = {
                    shop: product.shop,
                    products: [],
                };
            }

            // Add the product to the relevant shop group
            acc[shopId].products.push(product);

            return acc;
        }, {}),
    );

    const handleCartAmountChange = (amount, product) => () => {
        dispatch({
            type: "CHANGE_CART_AMOUNT",
            payload: { ...product, qty: amount },
        });
    };

    const getTotalPrice = () => 
        state?.cart.reduce((acc, item) => item.confirmCheckout ? acc + item.price * item.qty : acc, 0);
    

    const handleNavigate = (path) => () => {
        toggleSidenav();
        router.visit(path);
    };

    return (
        <Box width="100%" minWidth={380}>
            {/* HEADING SECTION */}
            <TopHeader toggle={toggleSidenav} total={cartList.length} />

            <Divider />

            <Box height={`calc(100vh - ${cartList.length ? "207px" : "75px"})`}>
                {cartList.length > 0 ? (
                    <Scrollbar>
                        {shopWithProducts.map((shopWithProduct, idx) => (
                            <Box mb={4} key={idx}>
                                <Box>
                                    <MiniCartHeader
                                        shopDetails={shopWithProduct.shop}
                                    />
                                </Box>

                                {shopWithProduct.products.map((product) => (
                                    <MiniCartItem
                                        item={product}
                                        key={product.SKU}
                                        handleCartAmountChange={
                                            handleCartAmountChange
                                        }
                                    />
                                ))}
                            </Box>
                        ))}
                    </Scrollbar>
                ) : (
                    <EmptyCartView />
                )}

                {/* CART ITEM LIST */}
                {/* {cartList.length > 0 ? (
                    <Scrollbar>
                        {cartList.map((item) => (
                            <MiniCartItem
                                item={item}
                                key={item.SKU}
                                handleCartAmountChange={handleCartAmountChange}
                            />
                        ))}
                    </Scrollbar>
                ) : (
                    <EmptyCartView />
                )} */}
            </Box>

            {/* CART BOTTOM ACTION BUTTONS */}
            {cartList.length > 0 ? (
                <BottomActions
                    total={currency(getTotalPrice())}
                    handleNavigate={handleNavigate}
                />
            ) : null}
        </Box>
    );
}
