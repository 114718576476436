import MenuItem from "@mui/material/MenuItem";
// STYLED COMPONENT

import {SearchResultCard} from "../styles/index.js";
import {Link} from "@inertiajs/react";
// ==============================================================


// ==============================================================
export default function SearchResult({
                                         results,
                                         value,
                                         setNavigating,
                                     }) {
    return (
        <SearchResultCard elevation={2}>
            {results.map(item => <Link
                href={item.title === 'View More' ? item.slug : `/products/${item.slug}`}
                key={item.slug} onClick={() => {
                setNavigating(true);
            }}

            >
                <MenuItem key={item.slug}>{item.title}</MenuItem>
            </Link>)}
            {(results.length === 0 && value?.length > 0) &&
                <MenuItem key={'404'} disabled>Nothing found...</MenuItem>
            }
        </SearchResultCard>
    )

        ;
}
