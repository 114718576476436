import {Fragment, useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
// MUI ICON COMPONENTS

import Menu from "@mui/icons-material/Menu";
import Clear from "@mui/icons-material/Clear";
// GLOBAL CUSTOM COMPONENT

import Scrollbar from "@/components/scrollbar";
// RENDER MENU LEVEL FUNCTION

import {renderLevels} from "./render-levels.jsx";
// NAVIGATION DATA LIST

import {updateNavigation} from "./modified-navigation.js";

export default function MobileMenu({user}) {
    const [openDrawer, setOpenDrawer] = useState(false);
    const [navigationArray, setNavigationArray] = useState(updateNavigation);
    useEffect(() => {
        if (user?.is_admin && !navigationArray.some(link => link.title === 'Admin')) {
            setNavigationArray([...updateNavigation, {
                title: "Admin",
                megaMenu: false,
                megaMenuWithSub: false,
                url: '/admin/products',
                extLink: true,
            }]);
        } else if (user?.role === 'vendor' && !navigationArray.some(link => link.title === 'Vendor')) {
            setNavigationArray([...updateNavigation, {
                title: "Vendor",
                megaMenu: false,
                megaMenuWithSub: false,
                url: '/vendor/products',
                extLink: true,
            }]);
        } else {
            setNavigationArray(updateNavigation)
        }
    }, [user]);
    const handleClose = () => setOpenDrawer(false);

    return <Fragment>
        <IconButton onClick={() => setOpenDrawer(true)} sx={{
            flexShrink: 0,
            color: "grey.600"
        }}>
            <Menu/>
        </IconButton>

        <Drawer anchor="left" open={openDrawer} onClose={handleClose} sx={{
            zIndex: 15001
        }}>
            <Box width="100vw" height="100%" position="relative">
                <Scrollbar autoHide={false} sx={{
                    height: "100vh"
                }}>
                    <Box px={5} py={8} maxWidth={500} margin="auto" position="relative" height="100%">
                        {
                            /* CLOSE BUTTON */
                        }
                        <IconButton onClick={handleClose} sx={{
                            position: "absolute",
                            right: 30,
                            top: 15
                        }}>
                            <Clear fontSize="small"/>
                        </IconButton>

                        {
                            /* MULTI LEVEL MENU RENDER */
                        }
                        {renderLevels(navigationArray, handleClose)}
                    </Box>
                </Scrollbar>
            </Box>
        </Drawer>
    </Fragment>;
}
