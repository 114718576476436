import { Fragment } from "react";
import { Heading, StyledLink } from "../styles/index.js";
import { ABOUT_LINKS } from "../data/index.js";
// ==============================================================


// ==============================================================
export default function AboutLinks({
  isDark
}) {
  return <Fragment>
      <Heading style={{ color: "#FFF"}}>About Us</Heading>

      <div>
        {ABOUT_LINKS.map((item, ind) => <StyledLink isDark={isDark} href={item.href} key={ind}>
            {item.title}
          </StyledLink>)}
      </div>
    </Fragment>;
}
