import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Add from "@mui/icons-material/Add";
import Close from "@mui/icons-material/Close";
import Remove from "@mui/icons-material/Remove";
import { FlexBox } from "@/components/flex-box";
import { H6, Tiny } from "@/components/Typography";
import { currency } from "@/lib";
import { Link } from "@inertiajs/react";
import Checkbox from "@mui/material/Checkbox";
import useCart from "@/hooks/useCart";

export default function MiniCartItem({ item, handleCartAmountChange }) {
    const { state, dispatch } = useCart();

    const handleCheckboxChange = (event) => {
        dispatch({
            type: "SET_CONFIRM_CHECKOUT",
            payload: {
                SKU: item.SKU,
                confirmed: event.target.checked,
            },
        });
    };

    return (
        <FlexBox
            py={2}
            px={2.5}
            key={item.id}
            alignItems="center"
            borderBottom="1px solid"
            borderColor="divider"
        >
            {/* Checkbox for confirmCheckout */}
            <Checkbox
                checked={item?.confirmCheckout}
                onChange={handleCheckboxChange}
                sx={{
                    mr: 2,
                }}
            />

            <FlexBox alignItems="center" flexDirection="column">
                <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    onClick={handleCartAmountChange(item.qty + 1, item)}
                    sx={{
                        height: 28,
                        width: 28,
                        borderRadius: 50,
                    }}
                    disabled={item.qty >= item.stock}
                >
                    <Add fontSize="small" />
                </Button>

                <H6 my="3px">{item.qty}</H6>

                <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    disabled={item.qty === 1}
                    onClick={handleCartAmountChange(item.qty - 1, item)}
                    sx={{
                        height: 28,
                        width: 28,
                        borderRadius: 50,
                    }}
                >
                    <Remove fontSize="small" />
                </Button>
            </FlexBox>

            <Link href={`/products/${item.id}`}>
                <Avatar
                    alt={item.name}
                    src={`/storage/${item.imgUrl}`}
                    sx={{
                        mx: 1,
                        width: 75,
                        height: 75,
                    }}
                />
            </Link>

            <Box
                flex="1"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
            >
                <Link href={`/products/${item.slug}`}>
                    <H6 ellipsis className="title">
                        {item.name}
                    </H6>
                </Link>
                <Tiny color="grey.600">
                    {item.SKU.replace("-affiliate", "")}
                </Tiny>
                {(item.variantObject?.color || item.variantObject?.size) && <br />}
                {item.variantObject?.color && (
                    <Tiny color="grey.600">
                        Color: {item.variantObject?.color}{" "}
                    </Tiny>
                )}
                {item.variantObject?.size && (
                    <Tiny color="grey.600">
                        | Size: {item.variantObject?.size}
                    </Tiny>
                )}
                <br />
                <Tiny color="grey.600">
                    {currency(item.price)} x {item.qty}
                </Tiny>
                <H6 color="primary.main" mt={0.5}>
                    {currency(item.qty * item.price)}
                </H6>
            </Box>

            <IconButton
                size="small"
                onClick={handleCartAmountChange(0, item)}
                sx={{
                    marginLeft: 2.5,
                }}
            >
                <Close fontSize="small" />
            </IconButton>
        </FlexBox>
    );
}
