import {Fragment, useEffect} from "react";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import clsx from "clsx";
// LOCAL CUSTOM HOOKS

import useHeader from "./hooks/use-header.js";
// GLOBAL CUSTOM COMPONENTS

import LazyImage from "@/components/LazyImage";
import FlexBox from "@/components/flex-box/flex-box";
// LOCAL CUSTOM COMPONENTS

import MobileHeader from "./components/mobile-header.jsx";
import DialogDrawer from "./components/dialog-drawer.jsx";
import CategoriesMenu from "./components/categories-menu.jsx";
import LoginCartButtons from "./components/login-cart-buttons.jsx";
// STYLED COMPONENTS

import {HeaderWrapper, StyledContainer} from "./styles/index.js";
import {Link} from "@inertiajs/react";
// ==============================================================


// ==============================================================
export default function Header({
                                   isFixed,
                                   className,
                                   midSlot,
                                   categories,
                                   auth,
                                   canResetPassword,
                               }) {
    const theme = useTheme();
    const downMd = useMediaQuery(theme.breakpoints.down(1150));
    const {
        dialogOpen,
        sidenavOpen,
        toggleDialog,
        toggleSidenav
    } = useHeader();
    const CONTENT_FOR_LARGE_DEVICE = <Fragment>
        {
            /* LEFT CONTENT - LOGO AND CATEGORY */
        }
        <FlexBox minWidth={100} alignItems="center">
            <Link href="/">
                <LazyImage src={"/storage/assets/images/logo-sm.png"} style={{maxWidth: '11rem'}} alt="logo"/>
            </Link>

            {
                /* SHOW DROP DOWN CATEGORY BUTTON WHEN HEADER FIXED */
            }
            {isFixed ? <CategoriesMenu categories={categories}/> : null}
        </FlexBox>

        {
            /* SEARCH FORM | NAVIGATION */
        }
        {midSlot}

        {
            /* LOGIN AND CART BUTTON */
        }
        <LoginCartButtons auth={auth} toggleDialog={toggleDialog} toggleSidenav={toggleSidenav}/>

        {
            /* LOGIN FORM DIALOG AND CART SIDE BAR  */
        }
        <DialogDrawer dialogOpen={dialogOpen} sidenavOpen={sidenavOpen} toggleDialog={toggleDialog}
                      toggleSidenav={toggleSidenav} canResetPassword={canResetPassword}/>
    </Fragment>;
    return <HeaderWrapper className={clsx(className)}>
        <StyledContainer>{downMd ? <MobileHeader auth={auth}/> : CONTENT_FOR_LARGE_DEVICE}</StyledContainer>
    </HeaderWrapper>;
}
