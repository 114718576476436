// LOCAL CUSTOM COMPONENTS
import Categories from "./categories.jsx";
import NavigationList from "./nav-list/index.js";
// STYLED COMPONENTS

import {NavBarWrapper, InnerContainer} from "./styles.js";
// DATA TYPES

// ==========================================================


// ==========================================================
export default function Navbar({
                                   categories,
                                   border,
                                   elevation = 2,
                                   hideCategories = false,
                                   user,
                               }) {
    return <NavBarWrapper hoverEffect={false} elevation={elevation} border={border}>
        {hideCategories ? <InnerContainer sx={{
            justifyContent: "center"
        }}>
            <NavigationList/>
        </InnerContainer> : <InnerContainer>
            {
                /* CATEGORY MEGA MENU */
            }
            <Categories categories={categories}/>

            {
                /* HORIZONTAL MENU */
            }
            <NavigationList user={user}/>
        </InnerContainer>}
    </NavBarWrapper>;
}
