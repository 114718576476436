import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
// LOCAL CUSTOM COMPONENT

import LogoSection from "./components/logo.jsx";
import AboutLinks from "./components/about-links.jsx";
import SocialLinks from "./components/social-links.jsx";
import CustomerCareLinks from "./components/customer-care-links.jsx";
// GLOBAL CUSTOM COMPONENTS

import { Paragraph } from "@/components/Typography";
// STYLED COMPONENTS

import { Heading } from "./styles/index.js";
import useTheme from "@mui/material/styles/useTheme";
export default function Footer1() {
    const theme = useTheme();
  return <Box component="footer" bgcolor={theme.palette.grey[900]} mb={{
    sm: 0,
    xs: 7
  }}>
      <Box component={Container} color="white" overflow="hidden" py={{
      sm: 10,
      xs: 4
    }}>
        <Grid container spacing={3}>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <LogoSection />
          </Grid>

          {
          /* ABOUT US LINKS */
        }
          <Grid item lg={2} md={6} sm={6} xs={12}>
            <AboutLinks />
          </Grid>

          {
          /* CUSTOMER CARE LINKS */
        }
          <Grid item lg={3} md={6} sm={6} xs={12}>
            <CustomerCareLinks />
          </Grid>

          {
          /* CONTACT & SOCIAL LINKS */
        }
          <Grid item lg={3} md={6} sm={6} xs={12}>
            {
            /* CONTACT INFORMATION */
          }
            <Heading style={{ color: "#FFF"}}>Contact Us</Heading>

            <Paragraph py={0.6}  color="grey.500" style={{ fontWeight: "900"}}>INFINICORE SDN. BHD. (628826-K)</Paragraph>

            <Paragraph py={0.6} color="grey.500">
              No. 17G, 7th Floor, Block 2 Worldwide Business Park, Jalan Tinju 13/50 Seksyen 13, 40675 Shah Alam Selangor
            </Paragraph>

            <Paragraph py={0.6} color="grey.500">
              Email: helpdesk@infinicore.com.my
            </Paragraph>

            <Paragraph py={0.6} mb={2} color="grey.500">
              Phone: 03-55101700
            </Paragraph>

            {
            /* SOCIAL LINKS WITH ICON */
          }
            <SocialLinks />
          </Grid>
        </Grid>
      </Box>
    </Box>;
}
