import {Fragment, useState} from "react";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
// MUI ICON COMPONENT

import Clear from "@mui/icons-material/Clear";
// CUSTOM ICON COMPONENTS

import Icon from "@/icons";
// LOCAL CUSTOM COMPONENTS

import DialogDrawer from "./dialog-drawer.jsx";
// GLOBAL CUSTOM COMPONENTS

import Image from "@/components/BazaarImage";
import {H6, Paragraph} from "@/components/Typography";
import { SearchInput } from "@/components/search-box";
import { MobileMenu } from "@/components/navbar/mobile-menu";
import { FlexBetween, FlexBox } from "@/components/flex-box";
// GLOBAL CUSTOM HOOK

import useCart from "@/hooks/useCart";
// LOCAL CUSTOM HOOK

import useHeader from "../hooks/use-header.js";
import {Link, router} from "@inertiajs/react";
import LazyImage from "@/components/LazyImage.jsx";
import PersonOutline from "@mui/icons-material/PersonOutline.js";
import LoginIcon from "@mui/icons-material/Login.js";
import MenuItem from "@mui/material/MenuItem";
import {ListItemIcon} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle.js";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined.js";
import Divider from "@mui/material/Divider";
import {Logout} from "@mui/icons-material";
import Menu from "@mui/material/Menu";
export default function MobileHeader({auth}) {
  const {
    state
  } = useCart();
  const {
    dialogOpen,
    sidenavOpen,
    searchBarOpen,
    toggleDialog,
    toggleSearchBar,
    toggleSidenav
  } = useHeader();
  const ICON_STYLE = {
    color: "grey.600",
    fontSize: 20
  };
    const ICON_COLOR = {
        color: "grey.600",
    };
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

  return <Fragment>
      <FlexBetween width="100%">
        {
        /* LEFT CONTENT - NAVIGATION ICON BUTTON */
      }
        <Box flex={1}>
          <MobileMenu user={auth.user} />
        </Box>

        {
        /* MIDDLE CONTENT - LOGO */
      }
        <Link href="/">
            <Image height={55} src="/storage/assets/images/logo-sm.png" alt="logo" />
        </Link>

        {
        /* RIGHT CONTENT - LOGIN, CART, SEARCH BUTTON */
      }
        <FlexBox justifyContent="end" flex={1}>
          <IconButton onClick={toggleSearchBar}>
            <Icon.Search sx={ICON_STYLE} />
          </IconButton>

            {
                auth?.user ?
                    <IconButton onClick={handleClick}>
                        <PersonOutline sx={ICON_COLOR}/>
                    </IconButton>
                    :
                    <IconButton onClick={toggleDialog}>
                        <LoginIcon sx={ICON_COLOR}/>
                    </IconButton>

            }

          <Badge badgeContent={state.cart.length} color="primary">
            <IconButton onClick={toggleSidenav}>
              <Icon.CartBag sx={ICON_STYLE} />
            </IconButton>
          </Badge>
        </FlexBox>
      </FlexBetween>

      {
      /* SEARCH FORM DRAWER */
    }
      <Drawer open={searchBarOpen} anchor="top" onClose={toggleSearchBar} sx={{
      zIndex: 9999
    }}>
        <Box width="auto" padding={2} height="100vh">
          <FlexBetween mb={1}>
            <Paragraph>Search</Paragraph>

            <IconButton onClick={toggleSearchBar}>
              <Clear />
            </IconButton>
          </FlexBetween>

          {
          /* CATEGORY BASED SEARCH FORM */
        }
          <SearchInput />
        </Box>
      </Drawer>

      {
      /* LOGIN FORM DIALOG AND CART SIDE BAR  */
    }
      <DialogDrawer dialogOpen={dialogOpen} sidenavOpen={sidenavOpen} toggleDialog={toggleDialog} toggleSidenav={toggleSidenav} />
      <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
              elevation: 0,
              sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                  },
                  '&::before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                  },
              },
          }}
          transformOrigin={{horizontal: 'right', vertical: 'top'}}
          anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
          <MenuItem onClick={handleClose}>
              <Link href='/user/profile' style={{display: 'flex'}}>
                  <ListItemIcon>
                      <AccountCircleIcon fontSize="small"/>
                  </ListItemIcon>
                  <H6>My Profile</H6>
              </Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
              <Link href='/user/orders' style={{display: 'flex'}}>
                  <ListItemIcon>
                      <ShoppingBagOutlinedIcon fontSize="small"/>
                  </ListItemIcon>
                  <H6>My Orders</H6>
              </Link>
          </MenuItem>
          <Divider/>
          <MenuItem onClick={() => {
              setAnchorEl(null);
              localStorage.removeItem("cart");
              router.post(route('logout'));
              window.location.replace('/');
          }}>
              <ListItemIcon>
                  <Logout fontSize="small"/>
              </ListItemIcon>
              Logout
          </MenuItem>
      </Menu>
  </Fragment>;
}
