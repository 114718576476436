import {useEffect, useRef, useState, useTransition} from "react";
import api from "@/utils/__api__/products";
import {router} from "@inertiajs/react";
import {H4} from "@/components/Typography.jsx";

export default function useSearch() {
    const parentRef = useRef();
    const [_, startTransition] = useTransition();
    const [category, setCategory] = useState("*");
    const [resultList, setResultList] = useState([]);
    const [categoryTitle, setCategoryTitle] = useState("All Categories");
    useEffect(() => {
        console.log(category)
    }, [category]);
// HANDLE CHANGE THE CATEGORY
    const handleCategoryChange = cat => {
        setCategory(cat.value);
        setCategoryTitle(cat.title);
    };
// FETCH PRODUCTS VIA API
    const getProducts = async (searchText, category) => {
        const response = await axios.get(`/products/quick-search`, {
            params: {
                q: searchText,
                category
            }
        });
        const resultArray = await response.data.products.splice(0, 10)
        resultArray.length !==0 && resultArray.push({
            title: 'View More',
            slug: `/products/search?q=${searchText}${response.data.category?`&category=${encodeURIComponent(response.data.category?.name)}`:''}`,
        })
        setResultList(resultArray);
    };

    const handleSearch = (value, setValue) => {
        startTransition(() => {
            setValue(value);
            if (!value) setResultList([]); else if (value && category !== "*") getProducts(value, category); else getProducts(value);
        });
    };

    // const handleDocumentClick = () => setResultList([]);

    // useEffect(() => {
    //     window.addEventListener("click", handleDocumentClick);
    //     return () => window.removeEventListener("click", handleDocumentClick);
    // }, []);
    return {
        category,
        parentRef,
        resultList,
        handleSearch,
        categoryTitle,
        handleCategoryChange,
    };
}
