import { Fragment } from "react";
import AppStore from "./app-store.jsx";
import Image from "@/components/BazaarImage";
import { Paragraph } from "@/components/Typography";
import {Link} from "@inertiajs/react";
export default function LogoSection() {
  return <Fragment>
      <Link href="/">
        <Image mb={2.5} src="/storage/assets/images/logo-sm.png" alt="logo" style={{ width: "12rem" }} />
      </Link>

      <Paragraph mb={2.5} color="grey.500">
          This is a specialized store for all your schooling needs. We are committed to serve you better, by creating convenience, affordable and unlimited choices. You can buy all your schooling needs anytime and anywhere. We deliver to your home.
      </Paragraph>

      <AppStore />
    </Fragment>;
}
